"use client";

import { ReactLenis, useLenis } from "lenis/react";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
function SmoothScrolling({
  children
}) {
  const pathname = usePathname();
  const lenis = useLenis();
  useEffect(() => {
    lenis?.scrollTo(0, {
      immediate: true
    });
  }, [pathname, lenis]);
  return <ReactLenis root options={{
    lerp: 0.1,
    duration: 1.5,
    smoothWheel: true
  }} data-sentry-element="ReactLenis" data-sentry-component="SmoothScrolling" data-sentry-source-file="SmoothScrolling.tsx">
      {children}
    </ReactLenis>;
}
export default SmoothScrolling;