"use client";

import { useContext } from "react";
import { useState } from "react";
import { createContext, ReactNode } from "react";
interface NavContextType {
  navRetracted?: boolean;
  setNavRetracted: (arg0: boolean) => void;
}
export const NavContext = createContext<NavContextType>({
  navRetracted: false,
  setNavRetracted: () => {}
});
interface NavProviderProps {
  children: ReactNode;
}
export const NavContextProvider = NavContext.Provider;
export function NavProvider({
  children
}: NavProviderProps) {
  const [navRetracted, setNavRetracted] = useState(false);
  return <NavContextProvider value={{
    navRetracted,
    setNavRetracted
  }} data-sentry-element="NavContextProvider" data-sentry-component="NavProvider" data-sentry-source-file="Nav.tsx">
      {children}
    </NavContextProvider>;
}
export function useNav() {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useNav must be used within a NavProvider");
  }
  return context;
}