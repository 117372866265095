import React from "react";
interface IfProps {
  condition: any;
  children: React.ReactNode;
}
const If: React.FC<IfProps> = ({
  condition,
  children
}) => {
  return Boolean(condition) ? <>{children}</> : null;
};
export default If;