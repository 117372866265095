import React, { useState, useEffect, useRef } from "react";
import styles from "./Transition.module.scss";
const Transition = ({
  children,
  isVisible = false,
  className = "",
  as = "div",
  style = {},
  enterAnimation = "fadeIn",
  exitAnimation = "fadeOut",
  duration = 500,
  easing = "ease",
  onEnter = () => {},
  onExit = () => {},
  ...props
}) => {
  const [animationState, setAnimationState] = useState(isVisible ? "entered" : "exited");
  const prevIsVisibleRef = useRef(isVisible);
  const nodeRef = useRef(null);
  useEffect(() => {
    if (isVisible !== prevIsVisibleRef.current) {
      if (isVisible) {
        setAnimationState("entering");
        onEnter();
      } else {
        setAnimationState("exiting");
        onExit();
      }
    }
    prevIsVisibleRef.current = isVisible;
  }, [isVisible, onEnter, onExit]);
  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;
    const handleAnimationEnd = () => {
      setAnimationState(isVisible ? "entered" : "exited");
    };
    node.addEventListener("animationend", handleAnimationEnd);
    return () => {
      node.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isVisible]);
  if (animationState === "exited" && !isVisible) return null;
  const animationClassName = animationState === "entered" ? styles[enterAnimation] : animationState === "exited" ? styles[exitAnimation] : "";
  const Component = as;
  // console.log("animationClassName", animationState, animationClassName);
  return <Component ref={nodeRef} className={`${styles.animatedTransition} ${animationClassName} ${className}`} style={{
    animationDuration: `${duration}ms`,
    animationTimingFunction: easing,
    ...style
  }} {...props} data-sentry-element="Component" data-sentry-component="Transition" data-sentry-source-file="Transition.tsx">
      {children}
    </Component>;
};
export default Transition;